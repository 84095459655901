*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
  /* background-color: #071C2D; */
}




