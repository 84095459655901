.el-main {
  box-sizing: border-box;
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  width: 100%;
  padding: 80px 0 0;
  position: relative;
  min-height: calc(100vh - 255px);
  background-color: #09162b;
  overflow: auto;
}

.ido {
  display: block;
  min-width: auto;
}

.tabs-container {
  display: flex;
  justify-content: center;
}

.tabs {
  height: 52px;
  background: #312f69;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 3px;
  z-index: 2;
}

.tab-pan {
  min-width: 145px;
  color: #fff;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1.4px;
}

.is-active {
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 3px 3px 0 transparent;
  text-shadow: 0 1px 1px #23233b;
  background: #1e1c40;
  border: 2px solid #565891;
}

.line-perch {
  height: 25px;
  border-left: 1px solid #03111c;
  margin: 0 10px;
}

.list {
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 69px auto 0;
  padding-bottom: 43px;
}

.item {
  width: 310px;
  background-color: #312f69;
  border-radius: 23px;
  /* box-shadow: 10px 10px 10px 10px #03111c; */
  margin-bottom: 60px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
  border: 0.5px solid #312f69;
  transition: all 0.3s ease-in-out;
  /* box-shadow: 0 10px 8px 0 #09162e; */
}

.no-item {
  display: none;
}

.empty-item {
  height: 500px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #9a9ab4;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
  margin: 0 auto;
}

.empty-item p {
  color: #fff;
}

.item-container {
  padding: 35px 26px;
  display: flex;
  flex-direction: column;
}

.left {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
}

.logo {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
}

.chain-info {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  padding-left: 84px;
  align-items: center;
}
.chain-logo {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.chain-name {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  padding: 5px 13px;
  /* background-color: #33334b; */
  background: #1e1c40;
  border-radius: 11px;
  border: 0.5px solid #565891;
}

.right {
  text-align: left;
}
.top-div {
  display: flex;
  flex-direction: column;
}
.ido-name {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 24px;
  margin: 0 0 16px;
  text-shadow: 0 1px 1px #1b1b31;
}

.amount-container {
  width: 100%;
  margin-bottom: 10px;
}

.amount-title {
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 0 1px 1px #20203a;
  margin-bottom: 1px;
}

.amount-value {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  color: #e9ebee;
  text-shadow: 0 1px 1px #20203a;
}

.time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 16px;
  height: 16px;
}

.perch {
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  background-color: #33334b;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.perch-center {
  width: 7px;
  height: 7px;
  background-color: #0077c9;
  border-radius: 50%;
}

.perch-line {
  position: absolute;
  height: 12px;
  width: 2px;
  background-color: #33334b;
  bottom: 11px;
  left: 5px;
}

.progress-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 5px;
}

.el-progress {
  position: relative;
  line-height: 1;
}

.el-progress-bar {
  padding-right: 0;
  margin-right: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.el-progress-bar__outer {
  height: 12px;
  background-color: #0077c918;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.el-progress-bar__inner {
  width: 100%;
  background-color: #0077c9;
  height: 10px;
  border-radius: 5px;
}

.ido-time {
  color: #73738d;
  margin-top: 13px;
  font-size: 15px;
}

.button-div {
  margin-top: 38px;
}

.ido-with-whitelist {
  display: flex;
  justify-content: center;
}
.ido-btn-white-list {
  color: #fff;
}
.ido-btn-detail {
  width: 250px;
  justify-content: center;
  text-align: center;
  height: auto;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: 0 #000;
  color: #fff;
  background-image: linear-gradient(180deg, #0077c9, #0077c9);
}

@media (max-width: 1000px) {
  .item {
    margin: 20px auto;
  }
  .list {
    overflow: hidden;
  }
}

@media (max-width: 677px) {
  .tab-pan {
    min-width: 82px;
    padding: 0 8px;
  }
  .item {
    margin: 10px auto;
  }
  .empty-item {
    margin: auto;
    padding-left: 11%;
    width: 70%;
  }
}

@media (max-width: 354px) {
  .tab-pan {
    min-width: 96px;
  }
}

.end-time {
  display: none;
}

.ttl {
  font-weight: 900;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 2px;
}
