.css-11xur9t-MuiPaper-root-MuiTableContainer-root,
.css-13xy2my {
  background-color: transparent !important;
  box-shadow: none !important;
  /* color: #fff; */
}

.css-15wwp11-MuiTableHead-root,
.css-1wbz3t9 {
  background-color: #0077c9;
  border-radius: 4px;
  /* color: #fff; */
}

.css-1q1u3t4-MuiTableRow-root {
  border-radius: 5px;
}

.css-1ygcj2i-MuiTableCell-root,
.css-1azl6jz-MuiTableCell-root,
.css-1bigob2 {
  color: #fff !important;
  border-bottom: none !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
}

.css-1ex1afd-MuiTableCell-root,
.css-177gid-MuiTableCell-root,
.css-q34dxg {
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
  border-bottom: none !important;
}

.width640 {
  width: 640px;
}

._center {
  padding-left: 26px !important;
}
